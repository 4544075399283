// client-login-button.tsx
"use client";

import React from "react";
import * as Popover from "@radix-ui/react-popover";
import { cn } from "@/lib/utils";


interface ClientLoginButtonProps {
  className?: string;
}

const ClientLoginButton: React.FC<ClientLoginButtonProps> = ({ className }) => {
  const loginUrl = "https://s.id/gJdq2";

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <button
          className={cn(
            "group flex h-8 w-8 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent hover:bg-primary focus:border-transparent focus:bg-primary dark:border-transparent dark:bg-white/[.15] dark:hover:bg-primary",
            className,
          )}
        >

          {/* icon light mode */}
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="dark-mode-light h-4 w-4 fill-slate-700 transition-colors group-hover:fill-white group-focus:fill-white dark:hidden">
            <path d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464l349.5 0c-8.9-63.3-63.3-112-129-112l-91.4 0c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304l91.4 0C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7L29.7 512C13.3 512 0 498.7 0 482.3z" />
          </svg>

          {/* icon dark mode */}
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="dark-mode-dark hidden h-4 w-4 fill-slate-700 transition-colors group-hover:fill-white group-focus:fill-white dark:block dark:fill-white">
            <path d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464l349.5 0c-8.9-63.3-63.3-112-129-112l-91.4 0c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304l91.4 0C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7L29.7 512C13.3 512 0 498.7 0 482.3z" />
          </svg>
        </button>
      </Popover.Trigger>

      <Popover.Content
        align="center"
        side="bottom"
        className="w-56 p-4 rounded-lg shadow-lg bg-white dark:bg-gray-800"
      >
        <p className="text-sm text-gray-800 dark:text-gray-200">
          Access the client area to view subscriptions and purchase history and other digital products.
        </p>
        <button
          onClick={() => window.open(loginUrl, "_blank")}
          className="mt-3 w-full px-3 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
        >
          Open Client Area
        </button>
      </Popover.Content>
    </Popover.Root>
  );
};

export default ClientLoginButton;










// "use client";

// import React from "react";
// import * as Popover from "@radix-ui/react-popover";
// import { UserButton, SignedIn, SignedOut } from "@clerk/nextjs";
// import { cn } from "@/lib/utils";

// interface ClientLoginButtonProps {
//   className?: string;
// }

// const ClientLoginButton: React.FC<ClientLoginButtonProps> = ({ className }) => {
//   const clientLoginUrl = "https://s.id/gJdq2";

//   return (
//     <>
//       {/* Saat user belum login */}
//       <SignedOut>
//         <Popover.Root>
//           <Popover.Trigger asChild>
//             <button
//               className={cn(
//                 "group flex h-8 w-8 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent hover:bg-primary focus:border-transparent focus:bg-primary dark:border-transparent dark:bg-white/[.15] dark:hover:bg-primary",
//                 className,
//               )}
//             >
//               {/* Icon light mode */}
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 viewBox="0 0 448 512"
//                 className="dark-mode-light h-4 w-4 fill-slate-700 transition-colors group-hover:fill-white group-focus:fill-white dark:hidden"
//               >
//                 <path d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464l349.5 0c-8.9-63.3-63.3-112-129-112l-91.4 0c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304l91.4 0C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7L29.7 512C13.3 512 0 498.7 0 482.3z" />
//               </svg>

//               {/* Icon dark mode */}
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 viewBox="0 0 448 512"
//                 className="dark-mode-dark hidden h-4 w-4 fill-slate-700 transition-colors group-hover:fill-white group-focus:fill-white dark:block dark:fill-white"
//               >
//                 <path d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464l349.5 0c-8.9-63.3-63.3-112-129-112l-91.4 0c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304l91.4 0C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7L29.7 512C13.3 512 0 498.7 0 482.3z" />
//               </svg>
//             </button>
//           </Popover.Trigger>

//           <Popover.Content
//             align="center"
//             side="bottom"
//             className="w-56 p-4 rounded-lg shadow-lg bg-white dark:bg-gray-800"
//           >
//             <p className="text-xs text-gray-800 dark:text-gray-200">
//             Access the client area to view subscriptions and purchase history and other digital products.
//             </p>
//             <button
//               onClick={() => window.open(clientLoginUrl, "_blank")}
//               className="mt-3 w-full px-3 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
//             >
//               Login to Client Area
//             </button>
//             <div>---</div>
//             <p className="text-xs text-gray-800 dark:text-gray-200">
//             If you want to access our free tools <em>(WhatsApp Link Generator and AI Business Name Generator)</em>, please log in to your user account first below.
//             </p>
//             <a
//               href="/sign-in"
//               className="mt-3 inline-block w-full px-3 py-2 text-center text-sm font-medium text-white bg-gray-700 rounded-md hover:bg-gray-800"
//             >
//               Login to Access tools
//             </a>
//           </Popover.Content>
//         </Popover.Root>
//       </SignedOut>

//       {/* Saat user sudah login */}
//       <SignedIn>
//         <UserButton afterSignOutUrl="/" />
//       </SignedIn>
//     </>
//   );
// };

// export default ClientLoginButton;

